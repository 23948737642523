import styled from "styled-components";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
function EmployeeCard(props) {
  return (
    <Wrapper>
      <div className="inner">
        <div className="image-holder">
          <img src={props.image} className="profile-pic" />
        </div>
        <div className="name">{props.name}</div>
        <div className="designation">{props.designation}</div>

        <div className="task">{props.task}</div>
        <LinkedInIcon className="Icon"/>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.section`
  height: 400px;
  width: 300px;
  font-family: "Montserrat", sans-serif;
  border-radius: 50px 50px 0px 50px;
  box-shadow: 10px 20px 10px rgba(0, 0, 0, 0.1);
  border:1px solid rgba(0, 0, 0, 0.05);
  padding: 1%;

  .background {
    height: 300px;
    width: 300px;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.3;
  }
  .inner {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
  }
  .task{
    font-size: 17px;
    font-weight: 500;
    
  }
  .Icon{
    color:#0077B5;
    font-size: 35px;
  }
  .name {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 500;
  }
  .designation {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 800;
    /* color: #1328bc; */
  }
  .profile-pic {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    z-index: 2;
  }
  .image-holder {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    position: relative;
    display: flex;
  }
  @media (max-width: 768px) {
    .image-holder {
    height: 180px;
    width: 180px;
  }
  
  .background {
    height: 220px;
    width: 220px;
  }
  }
`;
export default EmployeeCard;
