import styled from "styled-components";
import { useNavigate } from "react-router-dom";


function CardHolder2() {

  const navigate = useNavigate();

  return (
    <Wrapper>
      <div className="inner">
        <div className="maintext">Why Clear Cut Travel ?</div>
        <div className="cardholder">
          <div className="card">
            <div className="upperdiv">
              <img src={"https://pakistanatlas.com/wp-content/uploads/2023/02/All-Airlines-Logos@2x.png"} className="image" />
            </div>
            <div className="lowerdiv">
              <div className="lowtext">
              Choose from a wide range of domestic airlines to suit your travel needs. Enjoy the flexibility of picking the best airline for your journey.
              </div>
              <div className="card-button"    onClick={() => navigate("/ContactUs")}>
                <div className="button-text">Contact us</div>
              </div>
            </div>

            <div className="cardtitle">All International Airlines</div>
          </div>
          <div className="card">
            <div className="upperdiv">
              <img src={"https://www.worthnotworth.com/wp-content/uploads/2016/04/Logos-678x381.jpg"} className="image" />
            </div>
            <div className="lowerdiv">
              <div className="lowtext">
              Select from an extensive range of hotels for your perfect stay. Enjoy the convenience of choosing the ideal accommodation for your trip.
              </div>
              <div className="card-button"    onClick={() => navigate("/ContactUs")}>
                <div className="button-text">Speak to someone</div>
              </div>
            </div>

            <div className="cardtitle">More than 100K Hotels</div>
          </div>
          <div className="card">
            <div className="upperdiv">
              <img
                src={"https://argaamplus.s3.amazonaws.com/2ee4e9db-6c5a-4f46-9fa3-06500e127520.jpg"}
                className="image"
              />
            </div>
            <div className="lowerdiv">
              <div className="lowtext">
              Opt from a variety of transport options to fit your itinerary. Enjoy seamless travel with our diverse transportation choices.
              </div>
              <div className="card-button"    onClick={() => navigate("/ContactUs")}>
                <div className="button-text">Find out more</div>
              </div>
            </div>

            <div className="cardtitle">Extensive Transport Choices</div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 90vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;

  .cardtitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    width: 100%;
  }
  .upperdiv {
    height: 50%;
    width: 100%;
    border-radius: 15px 15px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .lowerdiv {
    height: 50%;
    width: 100%;
    border-radius: 0px 0px 15px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .lowtext {
    width: 80%;
    text-align: center;
    font-weight: 300;
    font-size: 20px;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .card-button {
    background-color: #1328bc;
    padding: 10px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    position: absolute;
    bottom: 20px;
  }
  .card-button:hover {
    cursor: pointer;
  }
  .button-text {
    color: white;
  }
  .image {
    height: 140px;
    width: 140px;
    border-radius: 50%;
    background-color: #f2f2f2;
    padding: 15px;
  }
  .cardholder {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .card {
    width: 30%;
    height: 475px;
    border-radius: 15px;
    display: flex;
    flex-flow: column;
    align-items: center;
    background-color: white;
    position: relative;
  }
  .maintext {
    font-size: 35px;
    font-family: "Roboto", sans-serif;
    text-align: center;
    font-weight: 600;
    margin-top: 2%;
    color: #333333;
  }
  
  .inner {
    height: 100%;
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 768px) {

  height: auto;
  
  .maintext {
    margin-top: 10%;
  }

  .cardholder {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .card {
    width: 90%;
    height: 475px;
    border-radius: 15px;
    display: flex;
    flex-flow: column;
    align-items: center;
    background-color: white;
    position: relative;
    margin-top: 5%;
  }

  .inner {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  }
`;

export default CardHolder2;
