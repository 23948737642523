import styled from "styled-components";
import image from "../PngItem_4590043.png";
import image2 from "../customer-service.png";
import { useNavigate } from "react-router-dom";

function CardHolder() {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <div className="inner">
        <div className="maintext">We manage it differently</div>
        <div className="subtext">
          We believe that people come first, and that everyone deserves a great
          experience every step of the way – whether it’s face to face, over the
          phone or online.
        </div>

        <div className="cardholder">
          <div className="card">
            <div className="upperdiv">
              <img src={image} className="image" />
            </div>
            <div className="lowerdiv">
              <div className="lowtext">
                Experience peace of mind with our 24/7 dedicated customer
                support, ensuring all your travel needs are met promptly and
                professionally.
              </div>
              <div
                className="card-button"
                onClick={() => navigate("/ContactUs")}
              >
                <div className="button-text">Find out more</div>
              </div>
            </div>

            <div className="cardtitle">24/7 Customer Support</div>
          </div>
          <div className="card">
            <div className="upperdiv">
              <img src={image2} className="image" />
            </div>
            <div className="lowerdiv">
              <div className="lowtext">
                Our dedicated and professional team is here to ensure every
                aspect of your journey is handled with expertise and care.{" "}
              </div>
              <div
                className="card-button"
                onClick={() => navigate("/ContactUs")}
              >
                <div className="button-text">Contact us</div>
              </div>
            </div>

            <div className="cardtitle">Dedicated & Professional Team</div>
          </div>
          <div className="card">
            <div className="upperdiv">
              <img
                src={"https://logodix.com/logo/871229.jpg"}
                className="image"
              />
            </div>
            <div className="lowerdiv">
              <div className="lowtext">
                Make secure payments with confidence, protected by our advanced
                security measures.
              </div>
              <div
                className="card-button"
                onClick={() => navigate("/ContactUs")}
              >
                <div className="button-text">Speak to someone</div>
              </div>
            </div>
            <div className="cardtitle">Secure Payments</div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 90vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;

  .cardtitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    width: 100%;
  }
  .upperdiv {
    height: 50%;
    width: 100%;
    border-radius: 15px 15px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .lowerdiv {
    height: 50%;
    width: 100%;
    border-radius: 0px 0px 15px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .lowtext {
    width: 80%;
    text-align: center;
    font-weight: 300;
    font-size: 20px;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .card-button {
    background-color: #1328bc;
    padding: 10px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    position: absolute;
    bottom: 20px;
  }
  .card-button:hover {
    cursor: pointer;
  }
  .button-text {
    color: white;
  }
  .image {
    height: 140px;
    width: 140px;
    border-radius: 50%;
    background-color: #f2f2f2;
    padding: 15px;
  }
  .cardholder {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .card {
    width: 30%;
    height: 475px;
    border-radius: 15px;
    display: flex;
    flex-flow: column;
    align-items: center;
    background-color: white;
    position: relative;
  }
  .maintext {
    font-size: 45px;
    font-family: "Roboto", sans-serif;
    text-align: center;
    font-weight: 600;
    margin-top: 2%;
    color: #333333;
  }
  .subtext {
    margin-top: 2%;
    width: 80%;
    text-align: center;
    font-size: 20px;
    color: #333333;
  }
  .inner {
    height: 100%;
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 768px) {
    height: auto;

    .maintext {
      font-size: 30px;
      font-weight: 600;
      color: #333333;
    }
    .inner {
      width: 100%;
    }
    .subtext {
      width: 90%;
      margin-bottom: 20px;
    }
    .cardholder {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .card {
      width: 90%;
      height: 530px;
      margin-bottom: 15px;
      border-radius: 15px;
      display: flex;
      flex-flow: column;
      align-items: center;
      background-color: white;
      position: relative;
    }
  }
`;

export default CardHolder;
