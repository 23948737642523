import styled from "styled-components";
import { useNavigate } from "react-router-dom";

function LongCard(props) {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <div className="main-div">
        <div className="text-holder">
          <div className="subtext">
            <div className="maintext">{props.maintext}</div>
            <div className="sub-text">{props.subtext}</div>
          </div>
          <div
          className="card-button"
            onClick={() => navigate(props.link)}
          >
            {props.buttontext}
          </div>
        </div>
        <div className="image-holder">
          <img className="image" src={props.image} />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 350px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;

  border-radius: 15px;

  .image-holder {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: end;
  }
  .card-button {
    background-color: #1328bc;
    padding: 10px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    width: 150px;
    height: auto;
    color: white;
  }
  .card-button:hover {
    cursor: pointer;
  }
  .image {
    height: 200px;
    width: 300px;
    object-fit: cover;

    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
  }
  .main-div {
    width: 75%;
    height: 65%;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
  }

  .maintext {
    font-weight: bold;
    font-size: 18px;
  }

  .sub-text {
    font-weight: 300;
    font-size: 17px;
  }

  .subtext {
    height: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .text-holder {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 15px;
    height: 70%;
  }
  @media (max-width: 768px) {
    height: auto;
    width: 100%;
    padding-top: 5%;
    border-radius: 0px;

    .main-div {
      width: 90%;
      height: auto;
      padding-top: 5%;
      padding-bottom: 5%;
      flex-direction: column-reverse;
      justify-content: space-evenly;
      align-items: center;
      border-radius: 15px 15px 15px 15px;
    }
    .text-holder {
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 100%;
    }
  }
`;

export default LongCard;
