import styled from "styled-components";
import UmrahCard from "./UmrahCard";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";

function UmrahPrompt(props) {
  return (
    <Wrapper>
      <div className="inner">
        <div className="maintext">{props.title}</div>
        <div className="cardholder">
          {props.data.map((user) => (
         
              <UmrahCard
                key={user.id}
                image={user.image}
                startingPrice={user.startingPrice}
                title={user.title}
                stars={user.stars}
                depart={user.depart}
                arrival={user.arrival}
              />
          ))}
        </div>
        <div className="swiperholder">
          <Swiper
            className="mySwiper"
            autoplay={{
              delay: 1000,
              disableOnInteraction: false,
            }}
          >
            {props.data.map((user) => (
              <SwiperSlide key={user.id}>
                <UmrahCard
                  key={user.id}
                  image={user.image}
                  startingPrice={user.startingPrice}
                  title={user.title}
                  stars={user.stars}
                  depart={user.depart}
                  arrival={user.arrival}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;

  .cardholder {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }
  .swiperholder {
    display: none;
  }

  .maintext {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    text-transform: uppercase;
    text-align: center;
  }
  .inner {
    height: 100%;
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 768px) {
    height: 700px;
    background-color: #f2f2f2;
    padding-bottom: 70px;

    .cardholder {
      flex-direction: column;
      display: none;
    }
    .inner {
      margin-top: 10%;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .swiperholder {
      display: grid;
      background-color: #f2f2f2;
      height: 100%;
      width: 100%;
    }

    .swiper {
      width: 100%;
      height: 100%;
      background-color: #f2f2f2;
    }

    .swiper-slide {
      background-color: #f2f2f2;
      text-align: center;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export default UmrahPrompt;
