import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Button from "@mui/material/Button";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CardHolder from "../components/CardHolder";
import LongCard from "../components/LongCard";
import CardHolder2 from "../components/CardHolder2";
import teamImage from "../fcc8f6d2-c246-4bdc-b998-a34bd41ece29.jpeg";
import UmrahPrompt from "../components/UmrahPrompt";
import HolidayPrompt from "../components/HolidaysPrompt";
import HajjPrompt from "../components/HajjPrompt";
import Reviews from "../components/Reviews";
import bg from "../bg.png";
import bgtop from "../background.png";
import mobilebg from "../mobile-background.png";
import React, { useEffect } from "react";

import image from "../4e81a26b-000a-4069-b751-ef8c320fa053.jpeg";
import image1 from "../7432091f-ff78-46c2-a8e8-f2ef71688a73.jpeg";
import image2 from "../052e750c-f38c-45fb-9ab3-21548b0d441b.jpeg";


import  image11 from "../WhatsApp Image 2024-07-14 at 23.36.52_3c1e61df.jpg"
import image55 from "../WhatsApp Image 2024-08-11 at 15.43.49_cfc19f0d.jpg"


import moroco from "../WhatsApp Image 2024-08-11 at 15.57.29_932ea129.jpg"





function Hero() {
  const phoneNumber = "447466716956";

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const Umrah1 = [
    {
      id: 1,
      image: image,
      startingPrice: 750,
      title: "October Umrah Package",
      stars: 5,
      depart: "05 Oct 2024",
      arrival: "15 Oct 2024",
    },
    {
      id: 2,
      image: image1,
      startingPrice: 700,
      title: "February Umrah Package",
      stars: 5,
      depart: "10 Feb 2025",
      arrival: "24 Feb 2025",
    },
    {
      id: 3,
      image: image2,
      startingPrice: 700,
      title: "December Umrah Package",
      stars: 5,
      depart: "20 Dec 2024",
      arrival: "30 Dec 2024",
    },
  ];

  const Hajj = [
    {
      id: 1,
      image: image,
      startingPrice: 5499,
      title: "Premium Hajj Package",
      stars: 5,
      depart: "01 May 2025",
      arrival: "15 Jun 2025",
    },
    {
      id: 2,
      image: image1,
      startingPrice: 4999,
      title: "Non Shifting Package",
      stars: 5,
      depart: "01 Jun 2025",
      arrival: "15 Jun 2025",
    },
    {
      id: 3,
      image: image2,
      startingPrice: 3999,
      title: "Shifting Hajj Package",
      stars: 5,
      depart: "02 Jun 2025",
      arrival: "16 Jun 2025",
    },
  ];

  const Holiday = [
    {
      id: 1,
      image: image11,
      startingPrice: 699,
      title: "Turkey Halal Escape",
      stars: 5,
      depart: "25 Mar 2025",
      arrival: "05 Apr 2025",
    },
    {
      id: 2,
      image: image55,
      startingPrice: 1299,
      title: "Maldives Luxury Halal Retreat",
      stars: 5,
      depart: "10 Nov 2024",
      arrival: "24 Nov 2024",
    },
    {
      id: 3,
      image: moroco,
      startingPrice: 799,
      title: "Moroccan Cultural Adventure",
      stars: 5,
      depart: "10 Oct 2024",
      arrival: "20 Oct 2024",
    },
  ];

  return (
    <Wrapper>
      <Header />
      <div className="outer">
        <div className="holder">
          <div className="blur-bg"></div>
          <div src={bg} className="bg-image2" />
          <img src={bgtop} className="bg-image" />
          <img src={mobilebg} className="bg-mobile" />

          <div className="swiper-holder">
            <div className="main-text">
              Experience spiritual
              <br />
              bliss with our
              <span className="bold"> Umrah</span> and
              <span className="bold"> Hajj</span>
              <br /> packages.
            </div>
            <div className="main-text2">
              Experience
              <br />
              spiritual bliss with our
              <span className="bold"> Umrah</span> and
              <span className="bold"> Hajj</span>
              <br /> packages.
            </div>
            <a
              href={`https://wa.me/${phoneNumber}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="text" className="button">
                <WhatsAppIcon className="icon" />
                Message Us
              </Button>
            </a>
          </div>
        </div>
      </div>
      <div className="second-div">
        <div className="titletext">
          Trust our travel agency for a seamless journey, handled with care and
          professionalism
        </div>
      </div>

      <CardHolder />

      <LongCard
        buttontext={"Speak with Expert"}
        image={
          "https://vid.alarabiya.net/images/2022/07/21/72cdc73e-dc0f-4f78-9b0f-b17efdc450d3/72cdc73e-dc0f-4f78-9b0f-b17efdc450d3_16x9_1200x676.jpg?width=801&format=jpg"
        }
        maintext={
          "Looking for a hassle-free travel experience? We've got you covered!"
        }
        subtext={
          " Get in touch with us today to explore our personalized travel solutions and start planning your ideal journey with ease!"
        }
        link={"/ContactUs"}
      />
      <LongCard
        buttontext={"Meet Our Team"}
        image={teamImage}
        maintext={"Meet Our Team: Dedicated Professionals Ready to Serve You"}
        subtext={
          "Our talented and experienced professionals are committed to providing exceptional service and innovative solutions tailored to your needs."
        }
        link={"/team"}
      />
      <CardHolder2 />
      <LongCard
        buttontext={"Contact Us"}
        image={
          "https://images.unsplash.com/photo-1697463624716-cd2f0423d9d9?q=80&w=1643&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        }
        maintext={"Give us a call"}
        subtext={
          "If you have an enquiry relating to any of our packages, products or services, please get in touch to speak to someone in our office and we’ll be happy to assist you."
        }
        link={"/ContactUs"}
      />
      <UmrahPrompt title={"Explore Umrah Package"} data={Umrah1} />
      <HajjPrompt title={"Explore Hajj Package"} data={Hajj} />
      <HolidayPrompt title={"Explore Halal Holidays Package"} data={Holiday} />
      <Reviews />
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 95vh;
  width: 100%;
  position: relative;
  background-color: #f2f2f2;
  font-family: "Montserrat", sans-serif;

  .loader-screen {
    height: 100%;
    width: 100%;
    background-color: white;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: opacity 1s ease-in-out;
  }

  .loader-icon.hidden {
    opacity: 0;
  }
  .loader-icon {
    width: 300px;
    height: 150px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: rotate 5s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  .second-div {
    height: 5vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f2f2f2;
  }
  .titletext {
    margin-top: 15px;
    font-style: italic;
    font-size: 18px;
    color: #333333;
  }
  .swiper-holder {
    position: absolute;
    height: 75%;
    width: 100%;
    top: 0px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .button {
    color: white;
    width: 165px;
    font-family: "Montserrat", sans-serif;
    margin-top: 15px;
    transition: color 0.3s ease;
  }
  .button #hover {
    cursor: pointer;
  }
  .icon {
    margin-right: 5px;
  }
  .bold {
    font-weight: 500;
  }
  .main-text {
    font-size: 47px;
    font-family: "Roboto", sans-serif;
    color: white;
    width: 55%;
    line-height: 47px;
    margin-bottom: 2%;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
  }

  .holder {
    height: 100%;
    width: 100%;
    position: relative;
    clip-path: polygon(0 0, 100% 0, 100% 93%, 0% 100%);
    background-color: #1328bc;
  }
  .outer {
    height: 100%;
    width: 100%;
  }
  .blur-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0;
  }
  .bg-image {
    height: 142.857%;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
  .bg-image2 {
    height: 100%;
    width: 100%;
    object-fit: fit;
    z-index: -1;
    position: absolute;
  }
  .bg-mobile {
    display: none;
  }
  .main-text2 {
    display: none;
  }
  @media (max-width: 768px) {
    height: 80vh;

    .main-text {
      display: none;
    }
    .main-text2 {
      display: inline;
      font-family: "Roboto", sans-serif;
      color: white;
      width: 55%;
      line-height: 47px;
      margin-bottom: 2%;
      text-align: center;
      font-family: "Montserrat", sans-serif;
      text-transform: uppercase;
      font-size: 24px;
      width: 85%;
    }
    .titletext {
      margin-top: 11px;
      font-size: 15px;
      text-align: center;
    }
    .second-div {
      height: 10vh;
      background-color: #f2f2f2;
    }
    .bg-image {
      display: none;
    }
    .holder {
      height: 100%;
      width: 100%;
      position: relative;
      background-color: #1328bc;
      clip-path: polygon(0 0, 100% 0, 100% 93%, 0% 100%);
    }
    .outer {
      height: 100%;
      width: 100%;
      background-color: #f2f2f2;
      top: 0px;
    }
    .bg-mobile {
      display: flex;
      width: 100%;
      object-fit: cover;
      object-position: top;
      position: absolute;
      bottom: -10%;
      clip-path: polygon(0 0, 100% 0, 100% 93%, 0% 100%);
    }
  }
`;

export default Hero;
