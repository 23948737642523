import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import { faSnapchat } from '@fortawesome/free-brands-svg-icons';

import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';




import snapchat from "../5296509_snapchat logo_ghost_snap_snapchat_social media_icon.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  const navigate = useNavigate();


    const handleScrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Smooth scroll to top
      });
    }
    

  return (
    <Wrapper>
      <div className="upper">
        <div className="topdiv">
          <div className="navi-holder">
            <div className="navi-button" onClick={() => navigate("/Umrah")}>
              Umrah
            </div>
            <div className="navi-button" onClick={() => navigate("/Hajj")}>
              Hajj
            </div>
            <div className="navi-button" onClick={() => navigate("/Holiday")}>
              Halal Holidays
            </div>
            <div
              className="navi-button"
              onClick={() => navigate("/Testimonials")}
            >
              Testimonials
            </div>
            <div className="navi-button" onClick={() => navigate("/Team")}>
              Team
            </div>
            <div className="navi-button" onClick={() => navigate("/ContactUs")}>
              Contact Us
            </div>
          </div>

          <div className="IconHolder">
         

          <a href="https://www.snapchat.com/add/clearcuttravel?share_id=RV9RbVcmghs&locale=en-GB">
            <FontAwesomeIcon icon={faSnapchat} className="social-icon44" />
            </a>

            <a href="https://x.com/clearcuttravel">
            <TwitterIcon className="social-icon" />
            </a>

            <a href="https://www.tiktok.com/@clearcuttravel?_t=8on37v8pbAO&_r=1">
            <FontAwesomeIcon icon={faTiktok} className="social-icon44" />
            </a>
            <a href="https://www.instagram.com/clearcuttravel?igsh=dWJodnJ3ODM4NDBn">
            <InstagramIcon className="social-icon" />
            </a>
            <a href="https://uk.linkedin.com/">
            <LinkedInIcon className="social-icon" />
            </a>
          </div>
        </div>
        <div className="lowerdiv">
          <div className="paragraph">
            ClearCutTravel is a top travel agency specializing in Umrah, Hajj,
            and Halal holidays. They provide comprehensive packages, handling
            all details from visas to accommodations, ensuring a seamless and
            spiritually enriching journey for the Muslim community.
          </div>
          <div className="paragraph2">
            ClearCutTravel also offers Halal holiday packages to various
            destinations with Halal-certified accommodations and activities.
            Whether you're seeking a beach getaway, an adventurous safari, or a
            cultural tour, ClearCutTravel ensures a fulfilling, value-respecting
            travel experience.
          </div>

          <div className="paragraph3">
            1, 1 Broker Road, London, N18 2US
            <br />
            Clear Cut Travel Ltd 2024
          </div>

          <div className="paragraph4">
            1, 1 Broker Road, London, N18 2US Clear Cut Travel © 2024.
          </div>
        </div>
      </div>

      <div className="copyright"></div>

      {/* <div className="privacy">
        <div className="privacyText">
          © 2024 Clear Cut Travel. Designed & Developed By{" "}
          <a
            href="https://www.linkedin.com/in/raja-ali-hassan-141b6b19a/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontStyle: "italic",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            RAH
          </a>
        </div>
      </div> */}

<KeyboardDoubleArrowUpIcon className="scroll-icon" onClick={handleScrollToTop}/>

    </Wrapper>
  );
};

const Wrapper = styled.section`
  height: 565px;
  width: 100%;
  padding-top: 5%;
  background-color: #f2f2f2;
  position: relative;

  font-family: "Montserrat", sans-serif;

  .scroll-icon{
    position: absolute;
    bottom: 5%;
    right: 5%;
    font-size: 50px;
    padding: 10px;
    background-color: rgba(255,255,255,0.85);
    border-radius: 50%;
    color:#1328bc;
  }
  .scroll-icon:hover {
    cursor: pointer;
  }
  .lowerdiv {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 2%;
  }
  .upper {
    height: 100%;
    background-color: #1328bc;
    clip-path: polygon(
      50% 6%,
      100% 0,
      100% 60%,
      100% 100%,
      0 100%,
      0% 60%,
      0 12%
    );
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  .paragraph2 {
    margin-top: 3%;
    color: white;
    font-size: 13px;
  }
  .paragraph3 {
    display: inline;
    margin-top: 5%;
    color: white;
    font-size: 12px;
    text-align: center;
    display: none;
  }
  .paragraph4 {
    display: inline;
    margin-top: 5%;
    color: white;
    font-size: 12px;
    text-align: center;
  }
  .paragraph {
    font-size: 13px;
    color: white;
  }
  .navi-holder {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .IconHolder {
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .social-icon {
    font-size: 60px;
    color: white;
  }
  .social-icon3 {
    font-size: 60px;
    color: white;
    height: 50px;
    width: 50px;
  }
  .social-icon44 {
    font-size: 60px;
    color: white;
    height: 50px;
    width: 50px;
  }
  .navi-button {
    font-size: 20px;
    color: white;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
  }
  .navi-button:hover {
    cursor: pointer;
  }

  .topdiv {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .privacy {
    width: 100%;
    height: 25px;
    background-color: #f2f2f2;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .privacyText {
    color: grey;
    letter-spacing: 5px;
    text-align: center;
  }
  .links {
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
  .link {
    display: flex;
    padding: 10px;
    flex-direction: row;
    margin: 1%;
  }
  .link:hover {
    cursor: pointer;
  }
  .arrowIcon {
    color: white;
    opacity: 0.65;
  }
  .linkText {
    font-size: 18px;
    color: white;
  }
  .sublist {
    width: 20%;
  }
  .head {
    font-size: 30px;
    color: #ecffff;
    font-family: "EB Garamond", serif;
  }
  .divider {
    height: 1px;
    background-color: white;
    opacity: 0.65;
  }

  @media (max-width: 768px) {
    height: 700px;

    .paragraph4 {
      display: none;
    }
    .social-icon3 {
      height: 15px;
      width: 15px;
      background-color: #c7d8ff;
      border-radius: 50%;
      padding: 5px;
      color: #0f1762;
      margin-bottom: 0px;
      
      margin-top: 2px;
  }
  .social-icon44 {
      height: 15px;
      width: 15px;
      background-color: #c7d8ff;
      border-radius: 50%;
      padding: 5px;
      color: #0f1762;
      margin-left:5px;
      margin-bottom: 4px;
  }
    .navi-button {
      font-size: 17px;
      color: white;
      font-weight: normal;
      margin-top: 15px;
      font-family: "Montserrat", sans-serif;
    }
    .paragraph3 {
      display: inline;
      margin-top: 15%;
      color: white;
      font-size: 12px;
      text-align: center;
    }
    .topdiv {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }

    .navi-holder {
      width: 90%;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      margin-top: 35px;
    }
    .IconHolder {
      width: 90%;
      margin-top: 35px;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
    }

    .upper {
      height: 100%;
      background-color: #1328bc;
      clip-path: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .social-icon {
      font-size: 15px;
      background-color: #c7d8ff;
      border-radius: 50%;
      padding: 5px;
      color: #0f1762;
      margin-left: 5px;
    }
    .lowerdiv {
      width: 90%;
      margin-top: 10%;
    }
    .privacy {
      display: none;
    }
    .privacyText {
      font-size: 11px;
      padding: 3%;
    }
    .scroll-icon{
    font-size: 40px;
  }
  }
`;

export default Footer;
